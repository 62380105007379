/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import RequiredAsterisk from '../../components/RequiredAsterisk';
import ReactQuill, { Quill } from 'react-quill';
import EmailApis from '../../api/email';
import { FiInfo } from 'react-icons/fi';
import { useAlert } from 'react-alert';
import { Modal, Input, Select, Spin } from 'antd';
import { handleErrorMessages, handleSuccessMessages } from '../../helpers/flashMessages';
import imageCompression from 'browser-image-compression';
import { PDFDocument } from 'pdf-lib';
import pako from 'pako';
import JSZip from 'jszip';

Quill.register(Quill.import('attributors/style/align'), true);

const SendEmailFields = ({
  emailFormData,
  setEmailFormData,
  isModalVisible,
  setIsModalVisible,
  text,
  setText,
  selectedRows,
  setSelectedRows,
  setSelectedEmails,
}) => {
  var modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['link', 'clean'],
      ['bold', 'italic', 'underline', 'strike'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { align: [] },
        { font: [] },
        { color: [] },
        { background: [] },
      ],
    ],
  };
  const [error, setError] = useState({});
  const alert = useAlert();
  const [loading, setLoading] = useState(false);  // Added loading state


  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancelClick = () => {
    setEmailFormData({});
    setText('');
    setIsModalVisible(false);
    setSelectedRows([]);
    setSelectedEmails([]);
  };

  const onFinish = async e => {
    e.preventDefault();
    if (!emailFormData.subject || emailFormData.subject == '') {
      return handleErrorMessages({ customMessage: 'Please enter the subject!' }, alert);
    } else if (!text || text == '' || text.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      return handleErrorMessages({ customMessage: 'Please enter the email body!' }, alert);
    }
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    if (emailFormData.files) {
      for (let i = 0; i < emailFormData.files.length; i++) {
        formData.append(`files`, emailFormData.files[i], emailFormData.files[i].name);
      }
    }
    formData.append('to', emailFormData.emails);
    formData.append('subject', emailFormData.subject.trim());
    formData.append('emailBody', text);
    formData.append('userIds', selectedRows);

    // Set loading state to true
    setLoading(true);

    try {
      const response = await EmailApis.sendBulkEmails(formData);
      setIsModalVisible(false);
      handleSuccessMessages(response, alert);
      setSelectedRows([]);
      setSelectedEmails([]);
      emailFormData.subject=''
      setText('')
    } catch (e) {
      const message = handleErrorMessages(e, alert);
      setError(message);
    } finally {
      // Set loading state back to false
      setLoading(false);
    }
  };

  // const fileHandler = e => {
  //   if (e.target.files.length > 10) {
  //     return handleErrorMessages({ customMessage: 'Select upto 10 files!' }, alert);
  //   }
  //   if (e.target.files) {
  //     for (let i = 0; i < e.target.files.length; i++) {
  //       if (e.target.files[i].size > 1048576) {
  //         return handleErrorMessages({ customMessage: 'File is too big!' }, alert);
  //       }
  //     }
  //   }
  //   setEmailFormData({ ...emailFormData, files: e.target.files });
  // };


const fileHandler = async (e) => {
  if (!e.target.files) return;

  let files = e.target.files;
  if (files.length > 10) {
    return handleErrorMessages({ customMessage: 'Select up to 10 files!' }, alert);
  }

  let compressedFiles = [];

  for (let i = 0; i < files.length; i++) {
    console.log('files',files);
    let file = files[i];

    if (file.size > 1048576) {
      return handleErrorMessages({ customMessage: 'File is too big!' }, alert);
    }

    let compressedFile = file; // Default file (if no compression applied)

    if (file.type.startsWith('image/')) {
      // Compress images
      const options = {
        maxSizeMB: 0.5, // Maximum size 500KB
        maxWidthOrHeight: 1024, // Resize to max 1024px
        useWebWorker: true,
      };
      compressedFile = await imageCompression(file, options);
    } else if (file.type === 'application/pdf') {
      // Compress PDFs
      const pdfDoc = await PDFDocument.load(await file.arrayBuffer());
      const compressedPdf = await pdfDoc.save();
      compressedFile = new File([compressedPdf], file.name, { type: 'application/pdf' });
    } else if (file.type === 'text/plain') {
      // Compress text files (gzip)
      const compressedText = pako.gzip(await file.text());
      compressedFile = new File([compressedText], file.name, { type: 'text/plain' });
    }
    // Compress DOCX using JSZip
    else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      try {
        const zip = new JSZip();
        zip.file(file.name, await file.arrayBuffer());
        const compressedDocx = await zip.generateAsync({ type: 'blob' });
        compressedFile = new File([compressedDocx], file.name, { type: file.type });
      } catch (error) {
        return handleErrorMessages({ customMessage: 'Error compressing DOCX!' }, alert);
      }
    }

    compressedFiles.push(compressedFile);
  }

  setEmailFormData({ ...emailFormData, files: compressedFiles });
};


  return (
    <Modal
      title="Send Email"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
      footer={null}
      maskClosable={false}
      preserve={false}
      destroyOnClose={true}
    >
      <form className="flex flex-col" encType="multipart/form-data" onSubmit={e => onFinish(e)}>
        <div>
          <label className="text-bold">
            Send To <RequiredAsterisk />
          </label>
          <div className="max-h-3px">
            <Select
              mode="tags"
              open={false}
              style={{ width: '100%' }}
              placeholder="Please select"
              className="mt-1 mb-3 close-icon"
              listHeight={100}
              name="to"
              value={emailFormData.emails}
            ></Select>
          </div>
        </div>
        <div>
          <label className="text-bold">
            Subject <RequiredAsterisk />
          </label>
          <Input
            name="subject"
            value={emailFormData.subject}
            className="mt-1 mb-3"
            onChange={e => setEmailFormData({ ...emailFormData, subject: e.target.value })}
          />
        </div>
        <div>
          <label className="text-bold">
            Email Body <RequiredAsterisk />
          </label>
          <ReactQuill
            modules={modules}
            name="text"
            value={text}
            className="mt-1 mb-2 email-editor"
            onChange={value => setText(value)}
            placeholder="Email body"
          />
        </div>
        <div>
          <label className="text-bold">File Upload: </label>
          <input
            type="file"
            className=""
            name="files"
            onChange={fileHandler}
            multiple
            accept=".png, .jpg, .jpeg, .doc, .docx, .svg, .pdf, .txt, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
        </div>
        <div className="flex mt-1 items-center text-text_grey">
          <FiInfo />
          <p className="ml-2">Only .png, .svg, .jpg, .jpeg, .txt, .pdf, and .docx format allowed!</p>
        </div>
        <div>
          <button
            type="submit"
            className="px-5 py-3 mt-5 text-white rounded-sm bg-primary hover:bg-primary-light modal-button"
            disabled={loading}  // Disable button while loading
          >
            {loading ? <Spin size="small" /> : 'Send'}
          </button>
          <button
            type="button"
            className="px-5 py-3 mt-5 mr-3 text-white rounded-sm bg-secondary_disabled modal-button"
            onClick={handleCancelClick}
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

SendEmailFields.propTypes = {
  emailFormData: PropTypes.object,
  setEmailFormData: PropTypes.func,
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  text: PropTypes.bool,
  setText: PropTypes.func,
  selectedRows: PropTypes.array,
  setSelectedRows: PropTypes.func,
  setSelectedEmails: PropTypes.func,
};

export default SendEmailFields;
