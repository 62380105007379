/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { handleErrorMessages, handleSuccessMessages } from '../../../../helpers/flashMessages';
import AdminAPIs from '../../../../api/admin';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

import BusinessUserFields from './businessUserFields';

const EditBusinessUser = () => {
  const [error, setError] = useState('');
  const { userId } = useParams();
  const [phone, setPhone] = useState('');
  const [businessUser, setBusinessUser] = useState({});
  const [email, setEmail] = useState();
  const { confirm } = Modal;
  const history = useHistory();
  const alert = useAlert();

  useEffect(() => {
    (async () => {
      try {
        const response = await AdminAPIs.getCustomer(userId);
        const { customer } = response.data;
        const { email } = customer;

        setPhone(customer.mobile);
        setBusinessUser({ ...customer });
        setEmail(email);
      } catch (e) {
        // const message = handleErrorMessages(e);
        // setError(message);
      }
    })();
  }, [userId]);
  const emailChange = async data => {
    try {
      const response = await AdminAPIs.updateCustomer(userId, data);
      if(response.status === 200){
        const userName = JSON.parse(localStorage.getItem('usersName'));
        const userNameIndex = userName.findIndex(data => data.id === response.data.UpdatedUser[0]._id);
        userName[userNameIndex].firstName = response.data.UpdatedUser[0].firstName;
        userName[userNameIndex].lastName = response.data.UpdatedUser[0].lastName;
        userName[userNameIndex].email = response.data.UpdatedUser[0].email;
        userName[userNameIndex].mobile = response.data.UpdatedUser[0].mobile;
        localStorage.setItem('usersName', JSON.stringify(userName));
      }
      history.push('/admin/business-user');
      handleSuccessMessages(response, alert);
    } catch (error) {
      const message = handleErrorMessages(error, alert);
      setError(message);
    
    }
  };
  const showEditConfirm = (userId, data) => {
    confirm({
      title: 'Are you sure you want to edit the email?',
      icon: <ExclamationCircleOutlined />,
      // content: 'The deleted record will not be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        console.log('ok');
        emailChange(data);
      },

      onCancel() {
        setBusinessUser({ ...businessUser, email: email });
      },
    });
  };

  const updateState = e => {
    const key = e.target.name;
    let value = e.target.value;
    if (key === 'mobile') {
      setBusinessUser({ ...businessUser, mobile: phone });
    }
    if (key == 'labourRate') {
      value =
        value.indexOf('.') >= 0
          ? value.substr(0, value.indexOf('.')) + value.substr(value.indexOf('.'), 3)
          : value;
    }
    if (key === 'status') {
      if(value === 'true'){
        // converts the string into a Boolean value
        value = true;
      } else if(value === 'false') {
          value = false;
      }
    }
    setBusinessUser({ ...businessUser, [key]: value });
    setBusinessUser({ ...businessUser, customerId: { ...businessUser.customerId, [key]: value }, [key]: value });
  };

  const onSubmit = async () => {
    try {
      // const { firstName, lastName, company, email, mobile, role, customerId } = user;
     const businessData = {
       firstName: businessUser.firstName,
       lastName: businessUser.lastName,
       mobile: businessUser.mobile,
       email: businessUser.email,
       garage: businessUser.customerId.garage,
       role: businessUser.role,
       customerData: {
         city: businessUser.city,
         province: businessUser.province,
         street: businessUser.street,
         postalCode: businessUser.postalCode,
         labourRate: businessUser.labourRate,
         status: businessUser.status,
         discount: businessUser.discount,
         company: businessUser.company,
       },
     };
     
 
      if (email !== businessData.email) {
        showEditConfirm(userId, businessData);
      } else {
        const response = await AdminAPIs.updateCustomer(userId, businessData);
        if(response.status === 200){
          const userName = JSON.parse(localStorage.getItem('usersName'));
          const userNameIndex = userName.findIndex(data => data.id === response.data.UpdatedUser[0]._id);
          userName[userNameIndex].firstName = response.data.UpdatedUser[0].firstName;
          userName[userNameIndex].lastName = response.data.UpdatedUser[0].lastName;
          userName[userNameIndex].email = response.data.UpdatedUser[0].email;
          userName[userNameIndex].mobile = response.data.UpdatedUser[0].mobile;
          localStorage.setItem('usersName', JSON.stringify(userName));
        }
           history.push('/admin/business-user');
        handleSuccessMessages(response, alert);
      }
    } catch (error) {
      const message = handleErrorMessages(error, alert);
      setError(message);
    }
  };

  return (
    
    <BusinessUserFields
      operation="edit"
      businessUser={businessUser}
      submitHandler={onSubmit}
      setBusinessUser={setBusinessUser}
      updateState={updateState}
      data={businessUser}
      phone={phone}
      setPhone={setPhone}
    />
  
  );
};

export default EditBusinessUser;
