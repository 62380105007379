/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { handleErrorMessages, handleSuccessMessages } from '../../../../helpers/flashMessages';

import AdminAPIs from '../../../../api/admin';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import CustomerFields from './customerFields';
import { any } from 'prop-types';
import { data } from 'autoprefixer';

const EditCustomers = () => {
  const [error, setError] = useState('');
  const { id } = useParams();
  const [customer, setCustomer] = useState({});
  const [email, setEmail] = useState();
  const history = useHistory();
  const { confirm } = Modal;
  const [phone, setPhone] = useState('');
  const alert = useAlert();
  const customerIdFields = [
    'province',
    'postalCode',
    'labourRate',
    'garage',
    'street',
    'city',
    'notes',
    'status',
    'discount',
    'company',
  ];

  useEffect(() => {
    (async () => {
      try {
        const response = await AdminAPIs.getCustomer(id);
        const { customer } = response.data;
        const { customerId } = customer;
        const { email } = customer;
        setCustomer({ ...customer });
        setEmail(email);
        // setCustomer({ ...customerId });
        setPhone(customer.mobile);
        setCustomer({ ...customerId });
        setCustomer({ ...customer });
        setCustomer({ ...customer, customerId: { ...customerId } });
      } catch (e) {
        // const message = handleErrorMessages(e);
        // setError(message);
      }
    })();
  }, [id]);

  const emailChange = async data => {
    try{
    const response = await AdminAPIs.updateCustomer(id, data);
    history.push('/admin/customer');
    handleSuccessMessages(response, alert);
    } catch (error) {
      const message = handleErrorMessages(error, alert);
      setError(message);
    
    }
  };
  const showEditConfirm = (id, data) => {
    confirm({
      title: 'Are you sure you want to edit the email?',
      icon: <ExclamationCircleOutlined />,
      // content: 'The deleted record will not be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        emailChange(data);
      },

      onCancel() {
        setCustomer({ ...customer, email: email });
      },
    });
  };

  const updateState = e => {
    const key = e.target.name;
    let value = e.target.value;
    const isCustomerIdField = customerIdFields.includes(key);
    if (key === 'mobile') {
      setCustomer({ ...customer, mobile: phone });
    }
    if (key == 'labourRate') {
      value =
        value.indexOf('.') >= 0
          ? value.substr(0, value.indexOf('.')) + value.substr(value.indexOf('.'), 3)
          : value;
    }
    if (isCustomerIdField) {
      if (key === 'status') {
        if (value === 'true') {
          // converts the string into a Boolean value
          value = true;
        } else if (value === 'false') {
          value = false;
        }
      }

      setCustomer({ ...customer, customerId: { ...customer.customerId, [key]: value }, [key]: value });
    } else {
      setCustomer({ ...customer, [key]: value });
    }
  };



  const onSubmit = async () => {
    try {
      // const { firstName, lastName, company, email, mobile, role, customerId } = user;
      let customerFieldData = {
        firstName: customer.firstName === undefined ? '' :  customer.firstName,
        lastName: customer.lastName,
        mobile: customer.mobile,
        email: customer.email,
        garage: customer.customerId.garage,
        role: customer.role,
        customerData: {
          city: customer.customerId.city,
          province: customer.customerId.province,
          street: customer.customerId.street,
          postalCode: customer.customerId.postalCode,
          labourRate: customer.customerId.labourRate,
          status: customer.status,
          discount: customer.customerId.discount,
          company: customer.customerId.company,
        },
      };
      if (email != customerFieldData.email) {
        showEditConfirm(id, customerFieldData);
      } else {
        const response = await AdminAPIs.updateCustomer(id, customerFieldData);
        // {
        // firstName,
        // lastName,
        // company,
        // email,
        // mobile,
        // role,
        // customerData: {
        //   city: customerId.city,
        //   province: customerId.province,
        //   street: customerId.street,
        //   postalCode: customerId.postalCode,
        //   labourRate: customerId.labourRate || 0,
        //   status: customerId.status,
        //   discount: customerId.discount || 0,
        //   company: role == 'customer' ? '' : customerId.company,
        // },
        // });
        if(response.status === 200){
          const userName = JSON.parse(localStorage.getItem('usersName'));
          
          const userNameIndex = userName.findIndex(data => data.id === response.data.UpdatedUser[0]._id);
        
          if(userNameIndex !== -1)
          {
          userName[userNameIndex].firstName = response.data.UpdatedUser[0].firstName;
          userName[userNameIndex].lastName = response.data.UpdatedUser[0].lastName;
          userName[userNameIndex].email = response.data.UpdatedUser[0].email;
          userName[userNameIndex].mobile = response.data.UpdatedUser[0].mobile;
          localStorage.setItem('usersName', JSON.stringify(userName));
          }
          else{
            let newArr = [...userName, { id: response.data.UpdatedUser[0]._id, firstName:response.data.UpdatedUser[0].firstName, lastName: response.data.UpdatedUser[0].lastName, email: response.data.UpdatedUser[0].email, mobile: response.data.UpdatedUser[0].mobile  }]
            localStorage.setItem('usersName', JSON.stringify(newArr));
          }
        }

        // set garage id
        const selectedGarageId = customer?.customerId?.garage || '';
        if (selectedGarageId) {
          localStorage.setItem('selectedGarage', selectedGarageId); // Save to localStorage
        }
        
        history.push('/admin/customer');
        handleSuccessMessages(response, alert);
      }
    } catch (error) {
      const message = handleErrorMessages(error, alert);
      setError(message);
    }
  };
  return (
  
    
    <CustomerFields
      operation="edit"
      customer={customer}
      submitHandler={onSubmit}
      setCustomer={setCustomer}
      updateState={updateState}
      data={customer}
      phone={phone}
      setPhone={setPhone}
    />
  
  );
};

export default EditCustomers;
