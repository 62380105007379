/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { handleErrorMessages, handleSuccessMessages } from '../../../../helpers/flashMessages';
import AdminAPIs from '../../../../api/admin';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

import UserFields from './userFields';

const EditUser = () => {
  const [error, setError] = useState('');
  const { id } = useParams();
  const [phone, setPhone] = useState('');
  const [user, setUser] = useState({});
  const [email, setEmail] = useState();
  const { confirm } = Modal;
  const history = useHistory();
  const alert = useAlert();
  const customerIdFields = [
    'province',
    'postalCode',
    'labourRate',
    'garage',
    'street',
    'city',
    'notes',
    'status',
    'discount',
    'company',
  ];

  useEffect(() => {
    (async () => {
      try {
        const response = await AdminAPIs.getUser(id);
        const { user } = response.data;
        const { email } = user;

        setPhone(user.mobile);
        setUser({ ...user });
        setEmail(email);
      } catch (e) {
        // const message = handleErrorMessages(e);
        // setError(message);
      }
    })();
  }, [id]);
  const emailChange = async data => {
   
    try{
      const response = await AdminAPIs.updateUser(id, data);
      if(response.status === 200){
        const userName = JSON.parse(localStorage.getItem('usersName'));
        const userNameIndex = userName.findIndex(data => data.id === response.data.UpdatedUser[0]._id);
        if(userNameIndex !== -1)
        {
        userName[userNameIndex].firstName = response.data.UpdatedUser[0].firstName;
        userName[userNameIndex].lastName = response.data.UpdatedUser[0].lastName;
        userName[userNameIndex].email = response.data.UpdatedUser[0].email;
        userName[userNameIndex].mobile = response.data.UpdatedUser[0].mobile;
        localStorage.setItem('usersName', JSON.stringify(userName));
        }
      }
      history.push('/admin/all-users');
      handleSuccessMessages(response, alert);
    } catch (error) {
      const message = handleErrorMessages(error, alert);
      setError(message);
    
    }
  };
  const showEditConfirm = (id, data) => {
    confirm({
      title: 'Are you sure you want to edit the email?',
      icon: <ExclamationCircleOutlined />,
      // content: 'The deleted record will not be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        emailChange(data);
      },

      onCancel() {
        setUser({ ...user, email: email });
      },
    });
  };

  const updateState = e => {
    const key = e.target.name;
    let value = e.target.value;
    const isCustomerIdField = customerIdFields.includes(key);
    if (key == 'mobile') {
      setUser({ ...user, mobile: phone });
    }
    if (key == 'labourRate') {
      value =
        value.indexOf('.') >= 0
          ? value.substr(0, value.indexOf('.')) + value.substr(value.indexOf('.'), 3)
          : value;
    }
    if (isCustomerIdField && !user.ownerId) {
      if (key === 'status') {
        value = value === 'true'; // converts the string into a Boolean value
      }

      setUser({ ...user, customerId: { ...user.customerId, [key]: value }, [key]: value });
    } else {
      setUser({ ...user, [key]: value });
    }
  };

  const onSubmit = async () => {
    try {
      // const { firstName, lastName, company, email, mobile, role, customerId } = user;
      var data;
      
      user?.role == 'admin'
        ? (data = {
            firstName: user?.firstName,
            lastName: user?.lastName,
            mobile: user?.mobile,
            email: user?.email,
            status: user?.status,
            role: user?.role,
          })
        : user.role == 'owner'
        ? (data = {
            firstName: user.firstName,
            lastName: user.lastName,
            mobile: user.mobile,
            email: user.email,
            status: user.status,
            garage: user.garage ? user.garage : user?.ownerId?.garage?.name,
            role: user.role,
            city: user.city,
            province: user.province,
            street: user.street,
            postalCode: user.postalCode,
            notes:user.notes
          })
        : (data = {
            firstName: user.firstName,
            lastName: user.lastName,
            mobile: user.mobile,
            email: user.email,
            garage: user.customerId.garage,
            role: user.role,
            customerData: {
              city: user.customerId.city,
              province: user.customerId.province,
              street: user.customerId.street,
              postalCode: user.customerId.postalCode,
              labourRate: user.customerId.labourRate,
              status: user.status,
              discount: user.customerId.discount,
              company: user.customerId.company,
            },
          });
      if (email != data.email) {
        showEditConfirm(id, data);
      } else {

        const response = await AdminAPIs.updateUser(id, data);
        // {
        // firstName,
        // lastName,
        // company,
        // email,
        // mobile,
        // role,
        // customerData: {
        //   city: customerId.city,
        //   province: customerId.province,
        //   street: customerId.street,
        //   postalCode: customerId.postalCode,
        //   labourRate: customerId.labourRate || 0,
        //   status: customerId.status,
        //   discount: customerId.discount || 0,
        //   company: role == 'customer' ? '' : customerId.company,
        // },
        // });
        if(response.status === 200){
          const userName = JSON.parse(localStorage.getItem('usersName'));
          const userNameIndex = userName.findIndex(data => data.id === response.data.UpdatedUser[0]._id);
          
          if(userNameIndex > -1){
            // console.log(userNameIndex,"USER");
          userName[userNameIndex].firstName = response.data.UpdatedUser[0].firstName;
          userName[userNameIndex].lastName = response.data.UpdatedUser[0].lastName;
          userName[userNameIndex].email = response.data.UpdatedUser[0].email;
          userName[userNameIndex].mobile = response.data.UpdatedUser[0].mobile;
          localStorage.setItem('usersName', JSON.stringify(userName));
        }

        handleSuccessMessages(response, alert);
        history.push('/admin/all-users');
        }
        
      }
    } catch (error) {
      const message = handleErrorMessages(error, alert);
      setError(message);
    }
  };

  return (
    <UserFields
      operation="edit"
      user={user}
      submitHandler={onSubmit}
      setUser={setUser}
      updateState={updateState}
      data={user}
      phone={phone}
      setPhone={setPhone}
    />
  );
};

export default EditUser;
