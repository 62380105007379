/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext } from 'react';
import { Form, Row, Select, AutoComplete} from 'antd';
import { FiArrowLeft } from 'react-icons/fi';
import { Link,useHistory } from 'react-router-dom';
import TextInput from '../../../../components/TextInput';
import RadioInput from '../../../../components/RadioInput';
import SelectInput from '../../../../components/SelectInput';
import CurrencyInput from '../../../../components/CurrencyInput';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import RequiredAsterisk from '../../../../components/RequiredAsterisk';
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber} from 'react-phone-number-input';
import { handleErrorMessages, handleSuccessMessages } from '../../../../helpers/flashMessages';
import AdminAPIs from '../../../../api/admin';
import GarageAPIs from '../../../../api/garage';
import { useAlert } from 'react-alert';
import _ from 'lodash';
import ReactAutocomplete from 'react-autocomplete';

const BusinessUserFields = ({
  operation,
  submitHandler,
  data,
  updateState,
  updateStateRadio,
  updateStateCompany,
  setBusinessUser,
  businessUser,
  phone,
  setPhone,
  setAdmin,
  admin,
}) => {
  
  const customStyles = {
    valueContainer: (base) => ({
      ...base,
      padding: "8px 10px"
    })
  };
  const history = useHistory()
  const updatedData = data;
  const alert = useAlert();
  const [form] = Form.useForm();
  const [garages, setGarages] = useState([]);
  const discountLevel = [0, 5, 10, 15];
  const [, forceUpdate] = useState({});
  // const [phone, setPhone] = useState('');
  const[statusActive,setStatusActive]=useState(false);
  const [statusInActive, setStatusInactive] = useState(false);
  const { Option, OptGroup } = Select;
  const [options, setOptions] = useState([]);
  const [lastNameSuggestions, setLastNameSuggestions] = useState([])
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    garage: '',
    discount: '',
    labourRate: '',
  });
  const customerIdFields = [
    'province',
    'postalCode',
    'labourRate',
    'street',
    'city',
    'notes',
    'status',
    'discount',
    'company',
  ];
  const emailString = /^\s*(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
  const [fname, setFName] = useState([]);
  const [lname, setLName] = useState([]);
  data.mobile = phone;
  useEffect(() => {
    const results = localStorage.getItem('usersName');
    var names = [JSON.parse(results) || []];
    for (let i = 0; i < names[0].length; i++) {
      fname.push(names[0][i]?.firstName);
      lname.push(names[0][i]?.lastName);
    }
    setFName(names[0]);
    setLName(names[0]);
  }, []);
  const firstNameOptions = fname.map((items, i) => {
    return { label: [items.firstName], value: items.id, data: items  };
  });
  const lastNameOptions = lname.map((items, i) => {
    return { label: [items.lastName], value: items.id, data: items  };
  });
  function handleChange(value) { }

  const nameChange = val => {
    const value = val?.value;
    const isNew = val?.__isNew__;
    // setBusinessUser({ ...businessUser, role: 'business_user' });
    {
      !isNew ? getCustomer(value) : '';
    }
  };

  const handleErrors = e => {
    let err = { ...errors };
    if (data.email == '') err.email = '* Enter email';
    else if (!data.email.match(emailString)) err.email = '* Enter a valid email';
    else if (data.email != '') err.email = '';
    if (data.firstName == '') err.firstName = '* Enter first name';
    if (data.firstName !== '' && !isValidName(data.firstName)) err.firstName = '* Please enter a valid name';
    else if (data.firstName != '') err.firstName = '';
    if (data.lastName == '') err.lastName = '* Enter last name';
    if (data.lastName !== '' && !isValidName(data.lastName)) err.lastName = '* Please enter a valid name';
    else if (data.lastName != '') err.lastName = '';
    if (data.labourRate == '') err.labourRate = '* Enter labour rate';
    if (data.labourRate != '') err.labourRate = '';
    if (data.discount == '') err.discount = '* Select discount';
    if (data.discount != '') err.discount = '';
    if (data.garage == '') err.garage = '* Select garage';
    if (data.garage != '') err.garage = '';
    if (phone == undefined || phone == '' && data.mobile == '') err.mobile = '* Enter mobile number';
    else if (!isValidPhoneNumber(phone || data.mobile)) err.mobile = '* Enter valid phone number';
    else if (isValidPhoneNumber(phone || data.mobile)) err.mobile = '';
    if (data.discount == 'Please Select') err.discount = '* Select discount';
    setErrors(err);
  };

  const discountError = (event)=>{
    if (data.discount == '') setErrors((prev) => ({ ...prev, ['discount']: ` * Select discount` }));
    if (data.discount == 'Please Select') setErrors((prev) => ({ ...prev, ['discount']: ` * Select discount` }));
     else if (data.discount != '') setErrors((prev) => ({ ...prev, ['discount']: `` }));
}

const garageError = ()=>{
  if (data.garage == '') setErrors((prev) => ({ ...prev, ['garage']: ` * Select garage` }));
  else if (data.garage != '') setErrors((prev) => ({ ...prev, ['garage']: `` }));
}

const onChangeError = (event)=>{
event.persist()
const decimalRegex = /^\d{1,}(\.\d{0,4})?$/
if(event.target.name==='labourRate'){
  if (data.labourRate == '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter labour rate` }));
  else if (data?.customerId?.labourRate.toString() != ''&& data?.customerId?.labourRate.toString().match(decimalRegex)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `` }));
  else if(!data.labourRate?.match(decimalRegex)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Please enter valid labour rate` }));
  else if (data?.labourRate != ''&& data?.labourRate.match(decimalRegex)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `` }));
  // if (data.labourRate != '' && Math.sign(data?.labourRate) < 1) setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Please enter valid labour rate` }));
  // if (data.discount != '' && Math.sign(data?.labourRate) === 1) setErrors((prev) => ({ ...prev, [event?.target?.name]: `` }));
  
}

if(event.target.name === 'email'){
  if (data.email == '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter email` }));
  else if (!data.email?.match(emailString)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter a valid email` }));
  else if (data.email != '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `` }));
}

if (event.target.name==="mobile"){
  if (phone == undefined || phone == '' && data.mobile == '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter mobile number` }));
  else if (!isValidPhoneNumber((phone || data.mobile))) setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter valid phone number` }));
  else if (data.mobile != '' || isValidPhoneNumber(phone)) setErrors((prev) => ({ ...prev, [event?.target?.name]: '' }));
}

if (event.target.name === "firstName"){
  if (event.target.value == '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter first name` }));
  else if (data?.firstName != '' && !isValidName(event.target.value)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Please enter a valid name` }));
  else if (event.target.value != '') setErrors((prev) => ({ ...prev, [event?.target?.name]: '' }))
}

if(event.target.name === "lastName"){
if (event.target.value == '') setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Enter last name` }));
else if (data?.lastName != '' && !isValidName(event.target.value)) setErrors((prev) => ({ ...prev, [event?.target?.name]: `* Please enter a valid name` }));
else if (event.target.value != '') setErrors((prev) => ({ ...prev, [event?.target?.name]: '' }))
}
}


  const getCustomer = async val => {
    const response = await AdminAPIs.getCustomer(val);
    const { customer } = response.data;
    setPhone(customer.mobile);
    setBusinessUser({
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      role: 'business_user',
    });
  };
  const [collapsed, setCollapsed] = useState('false');
  /********/

  useEffect(() => {
    (async () => {
      try {
        const response = await GarageAPIs.getGarages();
        const { garages } = response.data;
        const garageOptions = garages.map((item, i) => {
          return { label: item.name, value: item._id };
        });
        setGarages(garageOptions);
      } catch (e) {
        // const message = handleErrorMessages(e);
        // setError(message);
      }
    })();
  }, []);

  const [labelValue] = [...garages];

  const isValidName = name => {
   // return name && /^[a-zA-Z ]{2,30}$/.test(name);
   return name && /^[a-zA-Z&'-. ]{2,30}$/.test(name);
  };

  const handleSearch = async (value) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    if(value){
    setTypingTimeout(() =>
      setTimeout(async () => {
        const response = await AdminAPIs.getFirstNameSuggestions(value);
        const {usersName} = response.data;
        let firstNames = [];
        let lastNames = [];
        firstNames = usersName && usersName?.map((data, i) => {
          lastNames.push({key :i, value:data.lastName,})
          return { key :i, value:data.firstName, obj: data}
          })
        setOptions(firstNames);
        setLastNameSuggestions(lastNames);
      }, 1000)
    )};
  };

  const onChnageLastName =(val, obj)=>{
    setBusinessUser({...businessUser, lastName: val})
  }

  const handlePhone = (e) => {
    setPhone(e);
    handlePhoneErrors(e)
  }
  const handlePhoneErrors = (e) => {
    let err = { ...errors };
    let phone = e;
    if (phone == undefined || phone == '' && data.mobile == '') err.mobile = '* Enter mobile number';
    else if (!isValidPhoneNumber(phone)) err.mobile = '* Enter valid phone number';
    else if ( isValidPhoneNumber(phone)) err.mobile = '';
    setErrors(err)
  }

  const handleSubmit = e => {
    e.preventDefault();
    data.mobile = phone || data.mobile;
    setIsValidFirstName(isValidName(data?.firstName));
    setIsValidLastName(isValidName(data?.lastName));
    handleErrors();
    if (
      errors.firstName == '' &&
      errors.lastName == '' &&
      errors.email == '' &&
      errors.labourRate == '' &&
      errors.discount == '' &&
      errors.garage == '' &&
      isValidPhoneNumber(data.mobile) &&
      (data.email &&
        data.firstName &&
        data.lastName &&
        data.mobile &&
        data.labourRate &&
        data.discount) != ''
    ) {
    if (isValidName(data?.firstName) && isValidName(data?.lastName)) {
      if (data.mobile && data.mobile.length >= 10 && isValidPhoneNumber(data.mobile)) {
        const updatedUser = { ...data, mobile: phone };
        submitHandler(updatedUser);
      } else {
        handleErrorMessages({ customMessage: `Please enter a valid phone number.` }, alert);
      }
    }}  
  };
  const onChange = name => {
    if(operation == 'edit'){
      data.customerId.garage = name;
      setBusinessUser({ ...businessUser, customerId: { ...businessUser.customerId, garage: name } });
    }else {
      data.garage = name;
    }
  };

  const onDiscountChange = e => {
    if(operation !== "add"){
    data.customerId.discount = e.target.value;
    }else{
    data.discount = e.target.value;
    }updateState(e);
  }
  
  
  const onSearch = value => { };

  useEffect(()=>{
    setTimeout(()=>{
      if (data?.customerId) {
        if (data.customerId.status === true) {
          setStatusActive(true);
          setStatusInactive(false);
        } else if (data.customerId.status === false) {
          setStatusInactive(true);
          setStatusActive(false);
        }
      }
    },3000)
  },[data])

  const handleOnCancelClick = e => {
    e.preventDefault()
    history.push("/admin/business-user")
  }

  return (
    <>
      <div className="filter--outer mb-4">
        <div className="d-flex top--heading1">
          <h2 className="text-2xl font-semibold text-gr  ay-700 dark:text-gray-200">
            <Link to="/admin/business-user">
              <FiArrowLeft className="inline-block align-middle mr-2 text-base" />
              <p className="inline-block align-middle">
                {operation === 'add' ? 'Add Business User' : 'Edit Business User'}
              </p>
            </Link>
          </h2>
        </div>
      </div>
      <div className="FormWrapper">
        <form className="flex flex-col px-5 py-6 bg-white app_form" onSubmit={e => handleSubmit(e)} noValidate>
          <Row className="grid gap-4 grid-cols-1 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-4">
            <TextInput
              label="Company"
              onChange={updateState}
              name="company"
              value={operation !== "add" ? data?.customerId?.company: data?.company}
              placeholder="Company Name"
            />
            <div className="flex flex-col input__wrap">
              {/* <label className="inline text-small text-text mb-1">
                First Name
                <RequiredAsterisk />
              </label>
              <AutoComplete
              className=" bg-white text-body-2 text-text focus:outline-none rounded"
            onSearch={handleSearch}
            onChange={(val) => setBusinessUser({...businessUser, firstName: val})}
            onSelect={(val, options) => {
              const userData = options?.obj || {};
              const customMobile = options?.obj ? options.obj.mobile : "";
              (setBusinessUser || setBusinessUser)({ ...(businessUser || data), firstName: val, ...userData});
              setPhone(customMobile)
                            // handleAdminValidation(userData)
            }}
            value={data.firstName}
            placeholder="first name"
            options={options}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            />
              {/* <CreatableSelect
                isClearable
                className="bg-white text-body-2 text-subtext appearance-none focus:outline-none rounded"
                options={firstNameOptions}
                name="firstName"
                value={{value: data?.firstName, label: data?.firstName}}
                onChange={val => {
                  data.firstName = val?.value;
                  const customMobile = val?.data ? val?.data?.mobile : "";
                  const userData = val?.data || {};
                  (setAdmin || setBusinessUser)({ ...(admin || data), firstName: val?.value, ...userData});
                  setPhone(customMobile)
                }}
                styles={customStyles}
                onBlur={handleErrors}
                /> */}

            <TextInput
              label="First Name"
              onChange={updateState}
              name="firstName"
              value={data?.firstName}
              required={true}
              placeholder="First name"
              onBlur={onChangeError}
              onkeypress={onChangeError}
            />
                {/* {!isValidFirstName ? (
                  <p className="text-error font text-sm">* Please enter a valid first name</p>
                ) : null} */}
                {errors && errors.firstName !== '' ? (
                    <p className="text-error font text-sm">{errors.firstName}</p>
                  ) : null}
            </div>
            <div className="flex flex-col input__wrap">
              {/* <label className="inline text-small text-text mb-1">
                Last Name
                <RequiredAsterisk />
              </label>
              <AutoComplete
              className=" bg-white text-body-2 text-text focus:outline-none rounded"
              value={data?.lastName}
            onChange={onChnageLastName}
            onSelect={(val, options) => {
              setBusinessUser({...businessUser, lastName: val})
              //setLastInputName(val)
              // handleAdminValidation(userData)
            }}
            placeholder="last name"
            options={lastNameSuggestions}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            />
              {/* <CreatableSelect
                className="bg-white text-body-2 text-subtext appearance-none focus:outline-none rounded"
                options={lastNameOptions}
                name="lastName"
                isClearable
                selectOption={val => {
                  setBusinessUser({ ...businessUser, lastName: val });
                }}
                value={{value: data?.lastName, label: data?.lastName}}
                onChange={val => {
                  const customMobile = val?.data ? val?.data?.mobile : "";
                  data.lastName = val?.value;
                  const userData = {...(val?.data || {})};
                  (setAdmin || setBusinessUser)({ ...(admin || data), lastName: val?.value, ...userData });
                  setPhone(customMobile)
                }}
                styles={customStyles}
                onBlur={handleErrors}
              /> */}

            <TextInput
              label="Last Name"
              onChange={updateState}
              name="lastName"
              value={data?.lastName}
              required={true}
              placeholder="Last name"
              onBlur={onChangeError}
              onkeypress={onChangeError}
              />

              {/* {!isValidFirstName ? (
                <p className="text-error font text-sm">* Please enter a valid first name</p>
              ) : null} */}
              {errors && errors.lastName !== '' ? (
                  <p className="text-error font text-sm">{errors.lastName}</p>
                ) : null}
            </div> 
            <div>
              <label>
                Mobile Number
                <RequiredAsterisk />
              </label>
              <PhoneInput
                label="Mobile"
                international
                countryCallingCodeEditable={true}
                defaultCountry="CA"
                onChange={ e => handlePhone(e)}
                name="mobile"
                value={phone !== '' ? phone : data?.mobile}
                required={true}
                placeholder="123-456-7890"
                maxLength="16"
                minLength="10"
                onBlur={onChangeError}
                onkeypress={onChangeError}
                />
                {errors && errors.mobile !== '' ? (
                    <p className="text-error font text-sm">{errors.mobile}</p>
                  ) : null}
            </div>
            <div>
              <TextInput
                label="Email"
                onChange={updateState}
                name="email"
                value={data?.email}
                required={true}
                placeholder="john.doe@mail.com"
                onBlur={onChangeError}
                onkeypress={onChangeError}
                />
              {errors && errors.email !== '' ? (
                    <p className="text-error font text-sm">{errors.email}</p>
                  ) : null}
            </div>
            <div className="flex flex-col">
              <label className="inline text-small text-text mb-1">
                Garage
                <RequiredAsterisk />
              </label>
              <Select
                  showSearch
                  placeholder="Select garage"
                  optionFilterProp="children"
                  onChange={onChange}
                  onFocus={garageError}
                  onBlur={garageError}
                  onClick={garageError}
                  value={data?.customerId?.garage}
                  options={garages}
                  filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  />
                {errors && errors.garage !== '' ? (
                  <p className="text-error font text-sm">{errors.garage}</p>
                ) : null}
              {/* <Select
                showSearch
                placeholder="select garage"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                value={operation !== "add" ? {value: data?.customerId?.garage, label: } : data?.garage}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                {garages.map((garage, i) => (
                  <option key={i.toString()} value={garage._id}>
                    {_.startCase(garage.name)}
                  </option>
                ))}
              </Select> */}
            </div>
            {/* </SelectInput> */}
            
            <TextInput
              label="Street"
              onChange={updateState}
              name="street"
              value={operation !== "add" ? data?.customerId?.street :  data?.street}
              required={false}
              placeholder="e.g 100 Bayview Ave"
            />
            <TextInput
              label="City"
              onChange={updateState}
              name="city"
              value={operation !== "add" ? data?.customerId?.city: data?.city}
              required={false}
              placeholder="Toronto"
            />
            <TextInput
              label="Province"
              onChange={updateState}
              name="province"
              value={operation !== "add" ? data?.customerId?.province: data?.province}
              required={false}
              placeholder="Ontario"
            />
            <TextInput
              label="Postal Code"
              onChange={updateState}
              name="postalCode"
              value={operation !== "add" ? data?.customerId?.postalCode: data?.postalCode}
              required={false}
              placeholder="e.g M1M 1M1"
              maxLength="11"
            />
            {/* <div>
            <SelectInput
              label="Discount Level"
              name="discount"
              value={operation !== "add" ? data?.customerId?.discount: data?.discount}
              onChange={onDiscountChange}
              required={true}
              defaultOption="Select Discount Level"
              onBlur={handleErrors}
            >
              {discountLevel.map((level, i) => (
                <option key={i.toString()} value={level}>
                  {level + '%'}
                </option>
              ))}
            </SelectInput>
            {errors && errors.discount !== '' ? (
                  <p className="text-error font text-sm">{errors.discount}</p>
                ) : null}
            </div> */}
             <div>
              <SelectInput
                label="Discount Level"
                name="discount"
                value={data?.discount||data?.customerId?.discount}
                onChange={updateState}
                required={true}
                defaultOption="Select Discount Level"
                onFocus={discountError}
                onBlur={discountError}
                onClick={discountError}
              >
                {discountLevel.map((level, i) => (
                  <option key={i.toString()} value={level}>
                    {level + '%'}
                  </option>
                ))}
              </SelectInput>
              {errors && errors.discount !== '' ? (
                  <p className="text-error font text-sm">{errors.discount}</p>
                ) : null}
              </div>
            <div>
            {/* <CurrencyInput
              label="Labour Rate / Hour"
              onChange={updateState}
              name="labourRate"
              defaultValue={operation !== "add" ? data?.customerId?.labourRate : data?.labourRate}
              required={true}
              placeholder="0.00"
              onBlur={handleErrors}
              onkeypress={handleErrors}
            /> */}
              <TextInput
                label="Labour Rate / Hour"
                onChange={e => updateState(e, 'labourRate')}
                value={businessUser?.labourRate || data?.customerId?.labourRate}
                name="labourRate"
                required={true}
                placeholder="0.00"
                onBlur={onChangeError}
                onkeypress={onChangeError}
                // step="0.01"
              />
            {errors && errors.labourRate !== '' ? (
              <p className="text-error font text-sm">{errors.labourRate}</p>
            ) : null}
           </div>
            <div className="flex flex-col">
              <label className="inline text-small text-text mb-1">Status</label>
              <div className="flex flex-row mt-3 mb-10">
              <RadioInput
                label="Active"
                //  defaultChecked={data?.customerId?.userId?.status === true}
                onChange={e => updateState(e)}
                value={"true"}
                defaultChecked={operation === "edit" ? data?.customerId?.status === true: data.status === true}
                // checked={data?.status === true}
                // checked={data?.customerId?.status === true ? true : false}
                name="status"
                // defaultChecked
              />
              <RadioInput
                label="Inactive"
                //     defaultChecked={data?.customerId?.userId?.status === false}
                onChange={e => updateState(e)}
                value="false"
                  defaultChecked={operation === "edit" ? data?.customerId?.status === false : data.status === false}
                // checked={data?.status === false}
                //checked={data?.customerId?.status === false ? true : false}
                name="status"
                // defaultChecked
              />
              </div>
            </div>
          </Row>
          <div className="text-right">
            <button onClick={handleOnCancelClick} className="px-5 py-3 mt-5 mx-2 w-36 text-white text-button rounded-sm bg-primary hover:bg-opacity-80">
              Cancel
            </button>
            <button type="submit" className="px-5 py-3 mt-5 mx-2 w-43 text-white text-button rounded-sm bg-primary hover:bg-opacity-80">
              {operation === 'add' ? 'Add Business User' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
BusinessUserFields.propTypes = {
  title: PropTypes.string,
  operation: PropTypes.string,
  submitHandler: PropTypes.func,
  data: PropTypes.object,
  error: PropTypes.string,
  updateState: PropTypes.func,
  setBusinessUser: PropTypes.func,
  businessUser: PropTypes.object,
  phone: PropTypes.string,
  setPhone: PropTypes.func,
  setAdmin: PropTypes.func,
  admin: PropTypes.object,
};

export default BusinessUserFields;