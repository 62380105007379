/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from 'react';
import { Table, Button, Checkbox, Select, Input, Tooltip, Collapse, Modal } from 'antd';
import { ExclamationCircleOutlined, FilterOutlined } from '@ant-design/icons';
import {
  DownloadOutlined,
  DeleteOutlined,
  UploadOutlined,
  SearchOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import VehicleAPIs from '../../../api/vehicle';
import InoviceAPIs from '../../../api/invoice';
import _ from 'lodash';
import moment from 'moment';
import AdminAPIs from '../../../api/admin';

const Invoices = () => {
  const { Panel } = Collapse;
  const [collapse, setCollapse] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [nameOptions, setNameOptions] = useState([])
  const { Search } = Input;
  const { confirm } = Modal;
  const { Option } = Select;
  const suffix = (
    <SearchOutlined
      style={{
        fontSize: 16,
        color: '#F0734F',
      }}
    />
  );
  const [options, setOptions] = useState({
    search: '',
    carUser: '',
    vehicle: '',
    pagination: { current: 1, pageSize: 10, pageSizeOptions: [10, 20, 50] },
    sortFilters: {},
  });
  const columns = [
    {
      title: (
        <Checkbox
          onChange={e => onSelectAll(e)}
          checked={
            selectedRows.length > 0 &&
            _.isEqual(
              _.sortBy(admins.map(x => x._id)),
              _.sortBy(
                _.intersectionWith(
                  selectedRows,
                  admins.map(x => x._id),
                  _.isEqual
                )
              )
            )
          }
        />
      ),
      dataIndex: '_id',
      key: 'select',
      // eslint-disable-next-line react/display-name
      render: _id => <Checkbox onChange={e => onSelectRow(e, _id)} checked={selectedRows.includes(_id)} />,
    },
    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
      key: 'vehicle',
      sorter: true,
      render: vehicle =>
        `${vehicle?.plateNumber} / ${vehicle?.yearManufactured} / ${vehicle?.make} / ${vehicle?.model}`,
      },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
    },

    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
      sorter: true,
    },
    {
      title: 'Mileage',
      dataIndex: 'mileage',
      key: 'mileage',
      sorter: true,
    },
    {
      title: 'Repair Description',
      dataIndex: 'repairDescription',
      key: 'repairDescription',
      sorter: true,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      render: date => (date ? moment(date).format('MMMM Do YYYY') : 'NA'),
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
      sorter: true,
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      sorter: true,
    },
    {
      title: 'Actions',
      dataIndex: '_id',
      // eslint-disable-next-line react/display-name
      render: _id => (
        <>
          <Link to={`/super-admin/invoices/edit/${_id}`} 
          // onClick={e => editOnClick(e, _id)}
          >
            <Tooltip placement="top" title="edit">
              <EditOutlined />
            </Tooltip>
          </Link>
          <Tooltip title="delete">
            <DeleteOutlined className="ml-2" onClick={e => showDeleteConfirm(e, _id)} />
          </Tooltip>
        </>
      ),
    },
  ];



  const showDeleteConfirm = (e, _id) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'The deleted record will not be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        InoviceAPIs.deleteInvoice(_id);
        getInvoicesList(options);
        setSelectedRows([]);
      },

      onCancel() {
        setSelectedRows([]);
      },
    });
  };

  const showDelete = (e, selectedRows) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'The deleted record will not be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        InoviceAPIs.deleteInvoices([selectedRows]);
        getInvoicesList(options);
        setSelectedRows([]);
      },

      onCancel() {
        setSelectedRows([]);
      },
    });
  };


  const onSelectAll = e => {
    if (e.target.checked) {
      const test = [...new Set([...selectedRows, admins.map(n => n._id)].flat())];
      setSelectedRows(test);
    } else {
      const selectedIds = selectedRows.filter(n => !admins.map(x => x._id).includes(n));
      setSelectedRows(selectedIds);
    }
  };
  const onSelectRow = async (e, _id) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, _id]);
    } else {
      const selectedIds = selectedRows.filter(n => n !== _id);
      setSelectedRows(selectedIds);
    }
  };
  // const results = localStorage.getItem('usersName');
  // var names = [JSON.parse(results) || []];
  // const nameOptions = names[0].map((items) => {
  //   return { label: [items.firstName + ' ' + items.lastName], value: items.id };
  // });

  const collapseShowHideFunc = key => {
    setCollapse(key);
  };

  const handleClearAllClick = () => {
    setCollapse([]);
    const updatedOptions = {
      ...options,
      carUser: '',
      vehicle: '',
      pagination: { ...options.pagination, current: 1, pageSize: 10 },
    };
    setOptions(updatedOptions);
    getInvoicesList(updatedOptions);
  };

  const getAllVehicles = useCallback(async () => {
    if(options.carUser){
      const userVehicles = await VehicleAPIs.getCustomerVehicles(options.carUser);
      if(userVehicles.data.data.userWithVehicles){
        const vehiclesList = userVehicles?.data?.data?.userWithVehicles?.customerId?.vehicles;
        const vehiclesData = vehiclesList.map((car) => {
          return { label: [`${car.plateNumber} / ${car.yearManufactured} / ${car.make} / ${car.model}`], value: car._id}
        })
        setVehicles(vehiclesData);
      }
    }
  }, [options.carUser]);

  useEffect(() => {
    getAllVehicles();
  }, [getAllVehicles])

  useEffect(() => {
    (async () => {
      try {
        const response = await AdminAPIs.getCustomersName();
        const { usersName } = response.data;
        setNameOptions(usersName);
      } catch (e) {
        console.error('error in catch', e);
      }
    })();
  }, []);

  const getInvoicesList = useCallback(async options => {
    try {
      setLoading(true);
      const response = await InoviceAPIs.getInvoices(options);
      const { invoices } = response.data;
      setAdmins(invoices.docs)
      setOptions({
        ...options,
        pagination: {
          current: invoices.page,
          pageSize: invoices.limit,
          total: invoices.totalDocs,
          showSizeChanger: true,
        },
      });
    } catch (error) {
      console.error('Customer Error', error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getInvoicesList(options);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChange(pagination, filters, sorter) {
    // const {current, pageSize} = pagination;
    // const updatedOptions = { ...options, current, pageSize };
    const updatedOptions = { ...options, pagination };
    setOptions(updatedOptions);
    getInvoicesList({
      ...updatedOptions,
      sortFilters: {
        sortField: sorter?.columnKey,
        sortOrder: sorter?.order,
      },
    });
  }

  const handleFilterClick = () => {
    setCollapse([]);
   // getInvoicesList(options);
    const updatedOptions = {
      ...options,
      pagination: {
        current: 1
      },
    };
    getInvoicesList(updatedOptions);
    // setOptions({ ...options, vehicles: vehicles });
    // getAllVe(options);
    // getCustomerList({ ...options, pagination: { ...options.pagination, current: 1 } });
  };

  const filterSortedTable = e => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const query = e.target.value;
    const updatedOptions = {
      ...options,
      search: query.trim(),
      pagination: { ...options.pagination, current: 1 },
    };
    setOptions(updatedOptions);
    setTypingTimeout(() =>
      setTimeout(() => {
        getInvoicesList(updatedOptions);
      }, 1000)
    );
  };

  return (
    <>
      {/* <Helmet>
        <script
          src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js"
          type="text/javascript"
        />
        <script src="http://localhost:3000/js/init-alpine.js" type="text/javascript" />
      </Helmet> */}
      <div className="filter--outer mb-4">
        <div className="d-flex top--heading1">
          <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-200">Invoices</h2>
          <div className="right--btn">
            <button className="bg-primary hover:bg-opacity-80 py-2 px-4 text-white rounded-sm ">
              <Link to="/super-admin/invoice/add">
                <FiPlus className="inline-block align-middle mr-2 text-base" />
                <p className="inline-block align-middle">Add Invoice</p>
              </Link>
            </button>
          </div>
        </div>
        <div className="d-flex filter--wrap my-5">
          <div className="d-flex mb-0 mb-sm-0 gap-2">
            <div className="del--icon" style={selectedRows.length === 0 ? { cursor: 'default' } : {}}>
              <DeleteOutlined
                onClick={e => (selectedRows.length === 0 ? {} : showDelete(e, selectedRows))}
                style={selectedRows.length === 0 ? { cursor: 'default' } : {}}
              />
            </div>
            <div className="filter_content w-100 d-flex">
              <Collapse activeKey={collapse} onChange={collapseShowHideFunc}>
                <Panel
                  showArrow={false}
                  header={
                    <React.Fragment>
                      <FilterOutlined className="mr-2" style={{ marginBottom: '1px' }} />
                      <span className="anticon anticon-filter">Filters</span>
                    </React.Fragment>
                  }
                  key="1"
                  className="site-collapse-custom-panel"
                >
                  <div className="card card-body">
                    <div className="row">
                      <div className="col-12 filter_header d-flex flex-wrap position-relative">
                        <h4 className="title">Filter By</h4>
                      <button className="clearfilter_btn btn-default" 
                      onClick={handleClearAllClick}
                      >
                          Clear all
                        </button>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4 col-xl-2 fltr_cell">
                        <label className="label">Car User</label>
                        <Select
                          showSearch
                          optionFilterProp="children"
                          label="Car User"
                          className="text-1 font-normal rounded-r-none active-inactive-filter"
                          name="carUser"
                          defaultValue={'All'}
                          value={options.carUser}
                          onChange={e => setOptions({ ...options, carUser: e ,vehicle: ''})}
                        >
                          <Option value="">All</Option>
                          {nameOptions.map((customer, i) => (
                          <Option
                          key={i.toString()}
                          value={customer.id}
                          
                          >
                              {_.startCase(customer.firstName).concat(' ').concat(customer.lastName)}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4 col-xl-2 fltr_cell">
                        <label className="label">Vehicle</label>
                        <Select
                          disabled={options.carUser === ''}
                          showSearch
                          placeholder="select vehicle"
                          optionFilterProp="children"
                          name={'vehicle'}
                          onChange={e => {
                            setOptions({ ...options, vehicle: e });
                          }}
                          value={options.vehicle}
                          defaultValue={'All'}
                        >
                          <Option value="">All</Option>
                          {vehicles.map((vehicle, i) => (
                            <Option key={i.toString()} value={vehicle.value}>
                              {vehicle.label[0]}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4 col-xl-2 fltr_cell d-flex justify-content-end">
                        <label className="label"></label>
                        <button
                          type="button"
                          className="bg-primary hover:bg-opacity-80 py-2 px-4 text-white rounded-sm"
                          onClick={handleFilterClick}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </div>
          <div className="d-flex flex-wrap gap-2">
            <Button className="" type="primary" icon={<UploadOutlined />}>
              Import
            </Button>
            <Button className="" type="primary" icon={<DownloadOutlined />}>
              Export
            </Button>
            <Search
              className="search--wrap input-search-box   mt-0 mt-sm-0"
              style={{ width: '270px' }}
              placeholder="input search text"
              // enterButton="Search"
              size="large"
              // addonBefore={suffix}
              onChange={e => filterSortedTable(e)}
            />
          </div>
        </div>
      </div>
      <main className="overflow-y-auto main--table">
        <div className="grid mx-auto">
          <div className="w-full mb-8 overflow-hidden shadow-xs">
            <div className="w-full overflow-x-auto">
              <Table
                columns={columns}
                rowKey={record => record._id}
                dataSource={admins}
                onChange={onChange}
                pagination={options.pagination.total > 10 ? options.pagination : false}
                sortDirections={['ascend', 'descend']}
                loading={loading}
                scroll={{ scrollToFirstRowOnChange: true }}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default Invoices;
