/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { handleErrorMessages, handleSuccessMessages } from '../../../../helpers/flashMessages';

import AdminAPIs from '../../../../api/admin';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

import SuperAdminFields from './superAdminFields';

const EditSuperAdmin = () => {
  const [error, setError] = useState('');
  const { id } = useParams();
  const [admin, setAdmin] = useState({});
  const [phone, setPhone] = useState('');
  const history = useHistory();
  const [email, setEmail] = useState();
  const { confirm } = Modal;
  const alert = useAlert();

  useEffect(() => {
    (async () => {
      try {
        const response = await AdminAPIs.getAdmin(id);
        const { admin } = response.data;
        const { email } = admin;
        setAdmin({ ...admin });
        setEmail(email);
        setPhone(admin.mobile);
      } catch (e) {
        // const message = handleErrorMessages(e);
        // setError(message);
      }
    })();
  }, [id]);

  const updateState = e => {
    const key = e.target.name;
    let value = e.target.value;
    if (key === 'status') {
      value = value === 'true'; // converts the string into a Boolean value
      setAdmin({ ...admin, status: value });
    }
    if (key == 'mobile') {
      setAdmin({ ...admin, mobile: phone });
    }
    setAdmin({ ...admin, [key]: value });
  };

  const emailChange = async admin => {
    try{
    const response = await AdminAPIs.updateAdmin(id, admin);
    history.push('/admin');
    handleSuccessMessages(response, alert);
    } catch (error) {
      const message = handleErrorMessages(error, alert);
      setError(message);
    
    }
  };

  const showEditConfirm = (id, admin) => {
    confirm({
      title: 'Are you sure you want to edit the email?',
      icon: <ExclamationCircleOutlined />,
      // content: 'The deleted record will not be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        emailChange(admin);
      },

      onCancel() {
        setAdmin({ ...admin, email: email });
      },
    });
  };

  const onSubmit = async () => {
    try {
      if (email != admin.email) {
        showEditConfirm(id, admin);
      } else {
        const response = await AdminAPIs.updateAdmin(id, admin);
        if(response.status === 200){
          const userName = JSON.parse(localStorage.getItem('usersName'));
          const userNameIndex = userName.findIndex(data => data.id === response.data.user[0]._id);
          userName[userNameIndex].firstName = response.data.user[0].firstName;
          userName[userNameIndex].lastName = response.data.user[0].lastName;
          userName[userNameIndex].email = response.data.user[0].email;
          userName[userNameIndex].mobile = response.data.user[0].mobile;
          localStorage.setItem('usersName', JSON.stringify(userName));
        }
        history.push('/admin');
        handleSuccessMessages(response, alert);
      }
    } catch (error) {
      const message = handleErrorMessages(error, alert);
      setError(message);
    }
  };

  return (
    <SuperAdminFields
      operation="edit"
      submitHandler={onSubmit}
      setAdmin={setAdmin}
      updateState={updateState}
      data={admin}
      admin={admin}
      phone={phone}
      setPhone={setPhone}
    />
  );
};
export default EditSuperAdmin;
